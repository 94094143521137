import * as React from "react"
import { graphql } from "gatsby"

import HomeContent from "../components/home/home-content"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Home = ({ data, location }) => {
  // const siteTitle = data.site.siteMetadata?.title || `Myradio.Click`
  const posts = data.allMarkdownRemark.nodes
  const stations = data.stations.nodes

  return (
    <Layout dark={true} location={location} title={"Home"}>
      <HomeContent stations={stations} posts={posts} />
    </Layout>
  )
}

export default Home

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Home" />

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "post" } } }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 3
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
    stations: allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "station" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          featuredImage {
            childImageSharp {
              gatsbyImageData(
                width: 200
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  }
`
