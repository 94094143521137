import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export default function StationTease({ station }) {
  const title = station.frontmatter.title || station.fields.slug
  const image = getImage(station.frontmatter.featuredImage)
  return (
    <div className={" mb-4 text-center"}>
      <Link to={station.fields.slug} itemProp="url">
        <GatsbyImage image={image} alt={title} />
        <span itemProp="headline" className="sr-only">
          {title}
        </span>
      </Link>
    </div>
  )
}
