import React from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"

const test = [
  {
    quote:
      "MyRadio.Click is 5EBI’s provider of choice for On-Demand and Live Streaming facilities to our online listeners - emphatically. The back-end is very easy to learn and use, and can be tailored to our station’s needs - and Nick is constantly developing the features based our feedback.",
    position: "Production Coordinator",
    name: "Max Duncan",
    station: "5EBI",
    link: "/5ebi",
  },
  {
    quote:
      "We couldn't be happier with Nick's on demand playout system and the level of support he provides. Despite constant new needs and changes popping up on our end, Nick has never made us feel like we were competing for his attention.",
    position: "Station Manager",
    name: "Nikki Brogan",
    station: "FBi Radio",
  },
  {
    quote:
      "3MDR signed up to work on our new Live Stream, On-Demand and Program Pages with Nick and we've created a fantastic, user friendly set of audio assets that really add to our audience experience.  Nick is super responsive, very patient, really understands the community radio sector and is based right here in Australia!",
    position: "Engagement Coordinator",
    name: "Ren Cuttriss",
    station: "3MDR",
  },
  {
    quote:
      "We have worked with Nick from myradio.click for over 10 years and he has always kept us on the cutting edge. His new system Myradio.Click is a whole new level and his abilty to meet all our expectations is unmatched.",
    position: "Station Manager",
    name: "Tony Smythe",
    station: "Eastside Radio",
  },
  // {
  //   quote: "Donec mi odio faucibus at",
  //   position: "Manager",
  //   name: "Nikki Brogan",
  //   station: "FBi Radio",
  // },
  // {
  //   quote: "Donec mi odio faucibus at",
  //   position: "Manager",
  //   name: "Nikki Brogan",
  //   station: "FBi Radio",
  // },
  // {
  //   quote: "Donec mi odio faucibus at",
  //   position: "Manager",
  //   name: "Nikki Brogan",
  //   station: "FBi Radio",
  // },
  // {
  //   quote: "Donec mi odio faucibus at",
  //   position: "Manager",
  //   name: "Nikki Brogan",
  //   station: "FBi Radio",
  // },
  // {
  //   quote: "Donec mi odio faucibus at",
  //   position: "Manager",
  //   name: "Nikki Brogan",
  //   station: "FBi Radio",
  // },
  // {
  //   quote: "Donec mi odio faucibus at",
  //   position: "Manager",
  //   name: "Nikki Brogan",
  //   station: "FBi Radio",
  // },
  // {
  //   quote: "Donec mi odio faucibus at",
  //   position: "Manager",
  //   name: "Nikki Brogan",
  //   station: "FBi Radio",
  // },
  // {
  //   quote: "Donec mi odio faucibus at",
  //   position: "Manager",
  //   name: "Nikki Brogan",
  //   station: "FBi Radio",
  // },
  // {
  //   quote: "Donec mi odio faucibus at",
  //   position: "Manager",
  //   name: "Nikki Brogan",
  //   station: "FBi Radio",
  // },
  // {
  //   quote: "Donec mi odio faucibus at",
  //   position: "Manager",
  //   name: "Nikki Brogan",
  //   station: "FBi Radio",
  // },
]

export default function Testimonials() {
  const settings = {
    dots: false,
    infinite: false,
    arrows: false,
    centered: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          arrows: false,
          centerMode: false,
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          arrows: false,
          centerMode: false,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 960,
        settings: {
          arrows: false,
          centerMode: false,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerMode: false,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerMode: false,
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 200,
        settings: {
          arrows: false,
          centerMode: false,
          slidesToShow: 1,
        },
      },
    ],
  }
  return (
    <div className="w-full mb-36 mt-36">
      <div
        className={
          "w-11/12 md:w-6/12 xl:w-3/12 mx-auto px-2 flex max-w-full mb-8 md:mb-24"
        }
      >
        {test.map((item, i) =>
          i < 1 ? (
            <div key={i}>
              <div className="font-bold text-xl">{item.name}</div>
              <div className="mb-8">
                {item.station} - {item.position}
              </div>
              <div className={"italic text-lg mb-8"}>"{item.quote}"</div>
            </div>
          ) : null
        )}
      </div>
      <div className=" overflow-hidden">
        <div className={"w-11/12 "}>
          <Slider className="custom-slider" {...settings}>
            {test.map((item, i) =>
              i > 0 ? (
                <div className={"px-2 slick-element"} key={`carou${i}`}>
                  <div className={"bg-gray-900 lg:h-80  p-4 xl:p-6 rounded-lg"}>
                    <div className="font-bold text-xl mb-2">{item.name}</div>
                    <div className="mb-4 text-sm">
                      {item.station} - {item.position}
                    </div>

                    <div className="text-sm italic ">"{item.quote}"</div>
                  </div>
                </div>
              ) : null
            )}
          </Slider>
        </div>
      </div>
    </div>
  )
}
