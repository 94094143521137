import React from "react"
import Testimonials from "./testimonials"
import HomeStations from "./home-stations"
// import Contact from "../contact-form"
import HomeServices from "./home-services"
// import HomePosts from "./home-posts"
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export default function HomeContent({ stations = null, posts }) {
  const data = useStaticQuery(graphql`
    {
      allFile(filter: { name: { eq: "leader" } }) {
        edges {
          node {
            publicURL
            name
            image: childImageSharp {
              gatsbyImageData(
                width: 800
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  `)

  const image = getImage(data.allFile.edges[0]?.node.image)
  return (
    <div className="">
      <div className="home-featured block lg:flex items-center mb-8">
        <div className="pl-6 md:pl-16 lg:pl-24 pr-6 lg:pr-24 w-full lg:w-2/3 xl:w-1/2 mb-24 lg:mb-0">
          <h2 className={"home-loud-text font-bold mb-12"}>
            Focused on getting your{" "}
            <span className="text-teal-200">radio station</span> online and
            accessible by <span className="text-green-400">anyone</span>{" "}
            <span className="text-yellow-500">anywhere</span>{" "}
            <span className="text-blue-200">anytime</span>.
          </h2>
          <p className="mb-12 text-lg">
            Present your station's content on any device imaginable with our
            API's and website scripts fit for any circumstance.
          </p>
          <div className="flex justify-center lg:justify-start gap-4">
            <a
              href="#services"
              className="text-center px-4 py-2 lg:px-8 lg:py-4 border-2 border-blue-500 bg-blue-500 text-white rounded text-base lg:text-xl hover:bg-black"
            >
              LEARN MORE
            </a>
            <a
              href="/contact"
              className="text-center px-4 py-2 lg:px-8 lg:py-4 border-2 border-white text-white rounded text-base lg:text-xl hover:border-blue-500 hover:bg-blue-500"
            >
              ENQUIRE NOW
            </a>
          </div>
        </div>
        <div className="px-8 w-full lg:w-1/3 xl:w-1/2 text-center">
          <GatsbyImage image={image} alt={"Myradio.Click"} />
        </div>
      </div>

      <Testimonials />
      <div className="w-full lg:w-6/12 mx-auto px-8 lg:px-2  max-w-full mb-32">
        <h4 className="home-loud-text mb-12">
          Empower your presenters to{" "}
          <span className="text-red-500">create</span>{" "}
          <span className="text-yellow-500">share</span> and{" "}
          <span className="text-teal-500">grow</span> using our{" "}
          <span className="text-green-500">simple and poweful</span> interface
        </h4>
        <p className="text-gray-300 text-2xl mb-12">
          Grant your presenters access to build program, episode and profile
          information right from our online application. With a free
          myradio.click account, your presenters will easily generate web
          content that complements your broadcasts.
        </p>
        <p>
          <Link
            href="/contact"
            className="px-4 py-2 lg:px-8 lg:py-4 border-2 border-blue-500 bg-blue-500 text-white rounded text-lg lg:text-xl hover:bg-black"
          >
            ENQUIRE NOW
          </Link>
        </p>
      </div>
      <div id="services"></div>
      <HomeServices />

      <div className="w-full lg:w-6/12 mx-auto px-8 lg:px-2 max-w-full mb-32">
        <h4 className="home-loud-text mb-12">
          Getting started is <span className="text-yellow-500">simple</span>. No
          IT skills required
        </h4>
        <p className="text-gray-300 text-2xl mb-4">
          1 . Sign up at{" "}
          <Link
            to={"http://console.myradio.click"}
            target="_blank"
            className="font-bold text-white"
          >
            console.myradio.click
          </Link>
        </p>
        <p className="text-gray-300 text-2xl mb-4">
          2 . Add your station and program insformation.
        </p>{" "}
        <p className="text-gray-300 text-2xl mb-16">3 . Away you go!</p>
        <p>
          <Link
            to="/contact"
            className="px-4 py-2 lg:px-8 lg:py-4 border-2 border-blue-500 bg-blue-500 text-white rounded text-lg lg:text-xl hover:bg-black"
          >
            ENQUIRE NOW
          </Link>
        </p>
      </div>

      <HomeStations stations={stations} />
      {/* 
      <HomePosts posts={posts} /> */}
      <div className="w-full lg:w-6/12 mx-auto px-8 lg:px-2  max-w-full mb-32">
        <h4 className="home-loud-text mb-12">
          That just about covers everything, start sharing your radio online
          today. What are you waiting for?
        </h4>
        <Link
          to="/contact"
          className="px-4 py-2 lg:px-8 lg:py-4 border-2 border-blue-500 bg-blue-500 text-white rounded text-lg lg:text-xl hover:bg-black"
        >
          ENQUIRE NOW
        </Link>
      </div>
    </div>
  )
}
