import React from "react"
import StationTease from "../lists/station-tease"

export default function HomeStations({ stations }) {
  return (
    <div className="w-full bg-black  py-12">
      <div className={"w-full xl:w-full mx-auto px-2 flex max-w-full"}>
        <div className="w-full mb-4">
          <h2 className="mb-12 font-bold text-center">Some happy customers</h2>
          <div className={"flex justify-center items-center flex-wrap gap-12"}>
            {stations.map(station => {
              return (
                <StationTease key={station.fields.slug} station={station} />
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
