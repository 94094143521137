import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export default function HomeServices() {
  const data = useStaticQuery(graphql`
    {
      allFile(filter: { name: { eq: "stats" } }) {
        edges {
          node {
            publicURL
            name
            image: childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
          }
        }
      }
    }
  `)

  const image = getImage(data.allFile.edges[0]?.node.image)
  return (
    <div className={"home-services py-12 md:py-32"}>
      <h2 className={"font-bold text-center mb-32 text-4xl sr-only"}>
        OUR SERVICES
      </h2>
      <div className={"w-full  xl:w-8/12 mx-auto px-8 lg:px-2 max-w-full mb-8"}>
        <div className={"block md:flex gap-4 justify-center"}>
          <div className={"w-full md:w-1/3 mb-24 md:mb-12 lg:pr-12"}>
            <h3 className="font-bold text-2xl text-blue-200 mb-4 md:mb-12">
              Radio On-Demand
            </h3>
            <ul className="text-center">
              <li>Automate episode recordings</li>
              <li>Highlight content from your stream</li>
              <li>Customise episode content</li>
              <li>Access usage stats any time</li>
            </ul>
          </div>
          <div className={"w-full md:w-1/3 mb-24 md:mb-12 lg:pr-12"}>
            <h3 className="font-bold text-2xl text-yellow-500 mb-4 md:mb-12">
              Website Content
            </h3>
            <ul>
              <li>Complete wordpress integration available</li>
              <li>iFrame and APIs that do just about anything you need</li>
              <li>Automate live episode content</li>
              <li>Build program playlists</li>
              <li>Pin point content with just a few clicks</li>
            </ul>
          </div>
          <div className={"w-full md:w-1/3 mb-24 md:mb-12 lg:pr-12"}>
            <h3 className="font-bold text-2xl text-teal-200 mb-4 md:mb-12">
              Live Streaming
            </h3>
            <ul>
              <li>Live streams available</li>
              <li>We help you set everything up</li>
              <li>Various formats, codec and bitrates available</li>
            </ul>
          </div>
        </div>
      </div>
      <h2 className={"font-bold text-center mb-16 text-4xl "}>
        DETAILED USAGE REPORTS
      </h2>
      <div className="relative mx-8 md:mx-4 lg:mx-auto lg:max-w-3xl  bg-gray-700 shadow-md rounded md:rounded-lg max-h-[100vh] overflow-hidden select-none">
        <div className="flex items-center justify-center px-1 pt-1 pb-0   md:px-2 md:pt-2">
          <div className="relative flex items-center w-2/3 px-2 py-1 text-gray-100 bg-gray-600 rounded-md md:py-1 md:px-3">
            <span className="text-[length:10px] md:text-m-p-sm">
              myradio.click/dashboard
            </span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="absolute w-4 h-4 right-1 md:h-5 md:w-5"
              fill="none"
              viewBox="0 0 7 7"
            >
              <path
                fill="#fff"
                fill-opacity="0.8"
                d="M5.088 4.004l-.125.126.125.125.126-.125-.126-.126zm-1.073-.822l.948.948.251-.252-.948-.948-.251.252zm1.2.948l.947-.948-.251-.252-.948.948.251.252z"
              ></path>
              <path
                stroke="#fff"
                stroke-linecap="round"
                stroke-opacity="0.8"
                stroke-width="0.355"
                d="M4.26 4.966a1.659 1.659 0 11.829-1.436"
              ></path>
            </svg>
          </div>
          <div className="absolute flex gap-1 p-2 left-1 md:left-2 md:gap-2">
            <div className="w-2 h-2 bg-gray-400 rounded-full md:h-3 md:w-3"></div>
            <div className="w-2 h-2 bg-gray-400 rounded-full md:h-3 md:w-3"></div>
            <div className="w-2 h-2 bg-gray-400 rounded-full md:h-3 md:w-3"></div>
          </div>
        </div>
        <div className="px-2 pt-1 pb-2 md:px-4 md:pt-2 md:pb-4">
          <div className="relative bg-white text-gray-600 flex rounded md:rounded-lg overflow-hidden h-[35vh] md:h-[70vh]">
            <GatsbyImage
              className="w-full h-auto"
              image={image}
              alt={"Myradio.Click"}
            />
          </div>
          <div>
            <div className="absolute" style={{ top: "40%", left: "28%" }}>
              <svg
                className="relative w-7 -left-2"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 28 32"
              >
                <path
                  fill="#fff"
                  stroke="#000"
                  stroke-width="2"
                  d="M5.854 25.784l-4.69-9.602a1.634 1.634 0 01.729-2.167h0c.538-.277 1.002-.218 1.512.103.567.355 1.133 1.014 1.653 1.83.509.8.92 1.667 1.207 2.344a18.84 18.84 0 01.426 1.104l.004.013v.002h.003l1.948-.313V2.552c0-.868.692-1.552 1.522-1.552.83 0 1.522.684 1.522 1.552v0l.006 8.252v0h2s0 0 0 0c0-.872.774-1.637 1.6-1.637.872 0 1.606.726 1.606 1.552v2.552h2c0-.868.692-1.552 1.522-1.552.83 0 1.522.684 1.522 1.552v2.807h2c0-.868.693-1.552 1.522-1.552.83 0 1.522.684 1.522 1.552V17.471h.006L27 23.492s0 0 0 0C27 27.66 23.715 31 19.644 31h-6.726c-2.13 0-3.875-1.217-5.148-2.57a13.227 13.227 0 01-1.806-2.444 7.264 7.264 0 01-.108-.198l-.002-.004z"
                ></path>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
